<template>
  <v-app>
    <v-app-bar dense app color="teal lighten-3" elevate-on-scroll>
      <v-toolbar-title>
        Portfolio
        <v-avatar tile>
          <img src="https://www.pixenli.com/image/lGTaw1KM" />
        </v-avatar>
      </v-toolbar-title>
      <v-spacer />
      <v-btn text rounded v-for="(lnk, i) in links" :key="i" :to="lnk.link">
        {{ lnk.name }}
      </v-btn>
    </v-app-bar>
    <v-main>
      <router-view />
    </v-main>
    <Footeur />
  </v-app>
</template>

<script>
import Footeur from './views/Footeur'

export default {
  components: {
    Footeur
  },
  data: () => ({
    links: [
      { link: '/', name: 'Accueil' },
      { link: '/gallery', name: 'Galerie' },
      { link: '/tos', name: 'T.o.S' },
      { link: '/pricelist', name: 'Comission' }
    ]
  })
}
</script>
