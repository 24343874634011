<template>
  <v-container>
    <PresCarousel />
    <v-row class="mt-4">
      <v-col class="text-center">
        <h1 class="text-h2">WELCOME</h1>
        <h2 class="text-h5">Freelance illustration numérique</h2>
        <p class="mt-3">
          Je m'appelle Marie mais appelez moi Reasøn !
          <br />Je dessine au digital depuis Décembre 2011 où j'ai eu ma
          première tablette graphique. <br />Et depuis toujours, j'adore
          dessiner les animaux !
          <br />
          <br />Je suis également passionnée d'archéologie, de plantes et de
          jeux vidéos.
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PresCarousel from '@/components/PresCarousel.vue'

export default {
  name: 'Home',
  components: {
    PresCarousel
  }
}
</script>
