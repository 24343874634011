<template>
  <div>
    <v-carousel show-arrows cycle hide-delimiters>
      <v-carousel-item
        v-for="(item, i) in items"
        :key="i"
        :src="item.src"
        contain
        class="grey lighten-4"
      ></v-carousel-item>
    </v-carousel>
  </div>
</template>

<script>
import images_json from '@/assets/imagesJson.json'

export default {
  data() {
    return {
      items: images_json
    }
  },
  methods: {}
}
</script>
