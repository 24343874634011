import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/gallery',
    name: 'Gallery',
    component: () =>
      import(/* webpackChunkName: "gallery" */ '../views/Gallery.vue')
  },
  {
    path: '/verif_com',
    name: 'com_gal',
    component: () =>
      import(/* webpackChunkName: "com_gal" */ '../views/com_gal.vue')
  },
  {
    path: '/tos',
    name: 'ToS',
    component: () => import(/* webpackChunkName: "tos" */ '../views/ToS.vue')
  },
  {
    path: '/pricelist',
    name: 'Pricelist',
    component: () =>
      import(/* webpackChunkName: "pricelist" */ '../views/Pricelist.vue')
  },
  {
    path: '*',
    name: '404',
    component: () => import(/* webpackChunkName: "404" */ '../views/404.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
