<template>
  <v-footer dark padless app inset absolute>
    <v-card class="flex" flat tile>
      <v-card-text>
        <Contact />
      </v-card-text>
      <v-card-text class="py-2 white--text text-center">
        &copy; {{ new Date().getFullYear() }} —
        <strong>Reasøn (Marie COULAY)</strong>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
import Contact from '@/components/Contact.vue'

export default {
  components: {
    Contact
  },
  data: () => ({})
}
</script>
