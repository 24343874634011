<template>
  <v-row>
    <v-col cols="12">
      <v-row align="center" justify="center">
        <v-btn
          v-for="item in contact"
          :key="item.icon"
          :href="item.link"
          icon
          class="mx-4"
        >
          <v-icon>{{ item.icon }}</v-icon>
        </v-btn>
        <v-btn :href="contactAlt[0].link" icon class="mx-4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path
              d="M22 7h-7v-2h7v2zm1.726 10c-.442 1.297-2.029 3-5.101 3-3.074 0-5.564-1.729-5.564-5.675 0-3.91 2.325-5.92 5.466-5.92 3.082 0 4.964 1.782 5.375 4.426.078.506.109 1.188.095 2.14h-8.027c.13 3.211 3.483 3.312 4.588 2.029h3.168zm-7.686-4h4.965c-.105-1.547-1.136-2.219-2.477-2.219-1.466 0-2.277.768-2.488 2.219zm-9.574 6.988h-6.466v-14.967h6.953c5.476.081 5.58 5.444 2.72 6.906 3.461 1.26 3.577 8.061-3.207 8.061zm-3.466-8.988h3.584c2.508 0 2.906-3-.312-3h-3.272v3zm3.391 3h-3.391v3.016h3.341c3.055 0 2.868-3.016.05-3.016z"
            />
          </svg>
        </v-btn>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data: () => ({
    contact: [
      { icon: 'mdi-email', link: 'mailto:marie.coulay@gmail.com' },
      { icon: 'mdi-instagram', link: 'https://www.instagram.com/reasn_c/' },
      {
        icon: 'mdi-artstation',
        link: 'https://www.artstation.com/mariecoulay'
      },
      {
        icon: 'mdi-deviantart',
        link: 'https://www.deviantart.com/sunsguardian'
      },
      {
        icon: 'mdi-paw',
        link: 'https://www.furaffinity.net/user/oxreason/'
      }
    ],
    contactAlt: [
      {
        icon: '@/assets/behance_icon.svg',
        link: 'https://www.behance.net/mariecoula9669'
      }
    ]
  })
}
</script>
